<template>
  <div>
    <page-header title='Equipe'></page-header>
    <v-row justify="end" class="mr-4" v-if="true">
      <v-btn :disabled="$route.name == 'NewMember'" color="white" depressed rounded class="mx-2" @click="$router.push({name: 'NewMember'})"> 
        <v-icon class="mr-1" size="26" color="primary">mdi-plus-circle-outline</v-icon> 
        NOVO MEMBRO
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6">
        <v-card outlined style='border-radius: 18px'>
          <v-card-title class='primary white--text py-3 px-5'>
            Membros
          </v-card-title>
          <card-loading-transparent v-if="loading"/>
          <v-card-text class="px-0" v-if="!loading">
            <member-list :setLoading="handleSetLoading"></member-list>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card outlined style='border-radius: 18px;'>
          <v-card-text v-if="$route.name == 'Members' && !loading_form">
            <div class="grey--text title">
              Selecione um membro para exibir detalhes e edição.
            </div>
          </v-card-text>
          <card-loading-transparent loadingText="Carregando" v-if="loading_form"/>
          <div v-if="!loading_form">
            <router-view ></router-view>
          </div>
        </v-card>
        
      </v-col>
    </v-row>
    
  </div>
</template>

<script>

export default {
  name: 'MemberIndex',
  components: {
    MemberList: () => import ('./list/MemberList.vue'),
    NewMember: () => import ('./create/NewMember.vue')
  },
  data() {
    return {
      loading: false,
      selected_member: null,
      enable_fields: false,
      loading_form: false,
      member_list_ready: false,
    }
  },
  methods: {
    handleSetLoading(){
      this.getData()
    },
    getData(){
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 500);
    },
  },
  mounted () {
    this.getData();
  },
  watch: {
    '$route.path'() {
        this.loading_form = true
      setTimeout(() => {
        this.loading_form = false
      }, 400);
      if(this.$route.params.any_member_updated){
        this.getData();
      }
    },

  },
}
</script>

<style scoped>

</style>